<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="UPLOAD FLYERS"
    :size="modalSize"

    footer-class="d-block"
    no-close-on-backdrop
    no-close-on-esc
    :hide-footer="infoData.length===0"
    @hidden="closeModal"
  >

    <div>
      <CoolLightBox
        class="align-items-center"
        :items="itemImage"
        :index="showImage"
        @close="showImage = null"
      />
      <validation-observer ref="form">
        <b-col md="12">
          <b-row class="mt-2 mb-2">
            <b-col lg="5">
              <validation-provider
                v-slot="{errors}"
                name="Program"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class=" text-white bg-primary"

                      style="width: 130px"
                    >Program
                    </b-input-group-text>
                  </template>
                  <b-select
                    v-model="process.program"
                    :disabled="edit"
                    value-field="id"
                    text-field="value"
                    :options="programs"
                    :state="errors[0] ? false : null"
                    @change="validateProgram"
                  />

                </b-input-group>
                <label
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Select Program {{ errors[0] }}
                </label>
              </validation-provider>

            </b-col>
            <b-col lg="5">
              <validation-provider
                  v-slot="{errors}"
                  name="date"
                  rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                        class=" text-white bg-primary"

                        style="width: 130px"
                    >Date
                    </b-input-group-text>
                  </template>
                  <kendo-datepicker
                      id="created_date"
                      v-model="process.date"
                      v-mask="'##/##/####'"
                      :class="errors[0] ? 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caroV border-error-datepicker' : 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caroV'"
                      :min="minDate"
                      :max="maxDate"
                      :format="'MM/dd/yyyy'"
                      name="created_date"
                      :disabled="edit"
                      @change="validateDate"
                  />
                </b-input-group>
                <label
                    v-if="errors[0]"
                    class="text-danger"
                >
                  Select Date {{ errors[0] }}
                </label>
              </validation-provider>
            </b-col>

            <b-col lg="2">
              <div class="d-flex justify-content-end">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="searchFlyers"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="20"
                  />
                </b-button>
              </div>
            </b-col>

          </b-row>
          <b-row
            v-if="infoData.length>0"
            class="mt-3 "
          >
            <b-col lg="3">
              <h5 class="mb-1">
                FLYER
              </h5>

              <div v-for="(item, index) in infoData ">
                <div

                  class="d-flex flex-column  justify-content-center align-items-start clickable mb-2"
                  @click="openImage(index)"
                >
                  <b-avatar
                    v-b-tooltip.hover.left="'Click to view flyer'"
                    square
                    size="100"
                    v-bind="mainProps"
                    :src="item.route_thumb"
                    rounded="lg"
                    icon="file-earmark-image-fill"
                  />

                </div></div>
            </b-col>
            <b-col lg="3"><h5 class="mb-1">
                            REPLIES
                          </h5>
              <div
                v-for="item in infoData"
                class=" mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="'replies' + item.id"
                  rules="required"
                >
                  <div
                    style="height: 100px; width: 130px"
                    class="d-flex align-items-center "
                  >
                    <b-input-group>

                      <b-form-input
                        :id="'replies' + item.id"
                        v-model="item.replies"

                        :name="'replies' + item.id"
                        :state="errors[0] ? false : null"
                        @input="convert(item)"
                      />
                      <label
                        v-if="errors[0]"
                        class="text-danger"
                      >
                        Replies {{ errors[0] }}
                      </label>
                    </b-input-group>

                  </div>

                </validation-provider>

              </div>

            </b-col>
            <b-col lg="3"><h5 class="mb-1">
                            INVESTMENT
                          </h5>
              <div
                v-for="item in infoData"
                class=" mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="'investment' + item.id"
                  rules="required"
                >
                  <div
                    style="height: 100px; width: 130px"
                    class="d-flex align-items-center "
                  >
                    <b-input-group>
                      <money

                        v-model="item.invesment"
                        v-bind="vMoney"
                        class="form-control"
                        :class="{ 'border-error': errors[0] }"
                        @input="convert(item)"
                      />
                      <label
                        v-if="errors[0]"
                        class="text-danger"
                      >
                        Investment {{ errors[0] }}
                      </label>

                    </b-input-group>

                  </div>

                </validation-provider>

              </div>
            </b-col>
            <b-col lg="1"><h5 class="mb-1">
                            CPA
                          </h5>
              <div
                v-for="item in infoData"
                class=" mb-2"
              >
                <validation-provider>
                  <div
                    style="height: 100px; width: 130px"
                    class="d-flex align-items-center "
                  >
                    <span> $ {{ item.cpa | currency }} </span>

                  </div>
                </validation-provider>

              </div>
            </b-col>
            <b-col lg="2"> <h5
              class="mb-1 "
              style="margin-left: 2rem!important;"
            >
              ACTION
            </h5><div
              v-for="item in infoData"
              class="mb-2 d-flex justify-content-center"
            ><div
              style="height: 100px;"
              class="d-flex align-items-center"
            >
              <b-button
                class="d-flex align-items-center"
                variant="danger"
                size="sm"
                @click="deleteItem(item)"
              >  <feather-icon
                icon="Trash2Icon"
                size="15"
              /></b-button></div></div></b-col>
          </b-row>
          <b-row
            v-else
            class="mt-2"
          >
            <b-col lg="12">
              <H4 class="d-flex justify-content-center">
                Please select program and date first to select flyers
              </H4>
            </b-col>

          </b-row>
        </b-col>

      </validation-observer>
    </div>
    <template #modal-footer>

      <b-row>
        <b-col lg="3">
          <span>TOTAL</span>
        </b-col>
        <b-col lg="3">
          <div class=" caro7 footer-span w-75">
            <span class=" font-small-3  ">{{ sumaReplies }} </span>
          </div>

        </b-col>
        <b-col lg="3">
          <div class="caro7 footer-span w-75">
            <span class=" font-small-3  ">$ {{ sumaInvestment | currency }} </span>
          </div>

        </b-col>
        <b-col lg="2">
          <div class="caro7 footer-span w-75">
            <span class=" font-small-3  ">$ {{ sumaCpa | currency }} </span>
          </div>

        </b-col>

      </b-row>
      <div class="d-flex justify-content-end">
        <b-button

          :disabled="infoData.length<0"
          variant="primary"
          @click="insertProcessSale"
        >
          <b-spinner
            v-if="spinnerOn"
            small
          />
          SAVE
        </b-button>
      </div>
    </template>

    <search-flyer-modal
      v-if="searchFlyerModalOn"
      :process="process"
      @close="closeSearchFlyerModal"
    />
  </b-modal>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import SearchFlyerModal from '@/views/paragon/views/process-sale/procces-sale-module/modals/SearchFlyerModal'
import ProcessSaleService from '@/views/paragon/views/process-sale/process-sale.service'
import CoolLightBox from 'vue-cool-lightbox'
import moment from 'moment'

export default {
  components: {
    SearchFlyerModal,
    CoolLightBox,
  },
  props: {

    index: null,

  },
  data() {
    return {

      vMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      showImage: null,
      itemImage: [],
      mainProps: {
        center: false,
        fluidGrow: false,

        width: 100,

        block: false,

        fluid: true,

      },
      hidefooter: true,
      searchFlyerModalOn: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      spinnerOn: false,
      process: {},
      edit: false,
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      accepted: [],
      oldProgram: '',
      newProgram: '',
      oldDate: '',
      newDate: '',
      oldCampaign: '',
      newCampaign: '',
      campaigns: [],
      date: null,

    }
  },
  created() {
    this.date = moment().format('YYYY-MM-DD')
    this.process.date = moment().format('YYYY-MM-DD')
    this.pushImage()
    this.ownControl = true
  },
  computed: {

    ...mapGetters({
      uploadFlyersData: 'ProcessSaleStore/G_UPLOAD_FLYERS',
      currentUser: 'auth/currentUser',
      programs: 'SocialNetworkGlobalStore/G_PROGRAMSC',
      infoData: 'ProcessSaleStore/G_UPLOAD_FLYERS_INFO',
    }),

    modalSize() {
      if (this.screenWidth > 1600) return 'lg'
      return 'xmd'
    },
    sumaReplies() {
      return this.infoData.reduce((a, b) => a + (parseFloat(b.replies) || 0), 0)
    },
    sumaInvestment() {
      return this.infoData.reduce((a, b) => a + (parseFloat(b.invesment) || 0), 0)
    },
    sumaCpa() {
      return (this.sumaInvestment / this.sumaReplies)
    },

  },
  watch: {
    infoData() {
      this.pushImage()
    },
    'process.program': function (newVal, oldVal) {
      this.newProgram = newVal
      this.oldProgram = oldVal
    },
    'process.date': function (newVal, oldVal) {
      this.newDate = newVal
      this.oldDate = oldVal
    },
    'process.campaign_id': function (newVal, oldVal) {
      this.newCampaign = newVal
      this.oldCampaign = oldVal
    },

  },
  methods: {
    openImage(index) {
      this.showImage = index
    },
    pushImage() {
      const imageArray = []
      this.infoData.forEach(element => {
        imageArray.push({
          title: element.campaign,
          src: element.route,
          thumb: element.route_thumb,
        })
      })
      this.itemImage = imageArray
    },
    async deleteItem(dataReal) {
      this.infoData.splice(dataReal, 1)
      const data = this.uploadFlyersData.filter(item => item !== dataReal.bank_of_flyer_id)

      await this.uploudData(this.infoData)
      await this.uploudFlyers(data)
    },
    convert(item) {
      this.$set(item, 'cpa', ((item.invesment / item.replies)))
    },
    async validateCampaign() {
      if (this.infoData.length > 0) {
        const confirm = await this.showConfirmSwal()
        if (confirm.isConfirmed) {
          await this.resetData()
        } else {
          this.process.campaign_id = this.oldCampaign
        }
      }
    },
    async  validateProgram() {
      if (this.infoData.length > 0) {
        const confirm = await this.showConfirmSwal()
        if (confirm.isConfirmed) {
          await this.resetData()
        } else {
          this.process.program = this.oldProgram
        }
      }

      await this.getCampaingByProgramId()
    },
    async insertProcessSale() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            this.addPreloader()
            const params = {
              program_id: this.process.program,
              user_id: this.currentUser.user_id,
              date: this.process.date,
              process: this.infoData,
            }



            const data1 = await ProcessSaleService.insertNewProcessSale(params)

            if (data1.status === 200) {
              this.$emit('refresh')
              this.showSuccessSwal()
              this.removePreloader()
              await this.closeModal()
            }
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },

    async  validateDate() {
      if (this.infoData.length > 0) {
        const confirm = await this.showConfirmSwal()
        if (confirm.isConfirmed) {
          await this.resetData()
        } else {
          this.process.date = this.oldDate
        }
      }
    },

    ...mapMutations({
      uploudFlyers: 'ProcessSaleStore/M_SET_UPLOAD_FLYERS',
      uploudData: 'ProcessSaleStore/M_SET_UPLOAD_FLYERS_INFO',
    }),
    closeSearchFlyerModal() {
      this.searchFlyerModalOn = false
    },

    async resetData() {
      const data = []
      await this.uploudData(data)
      await this.uploudFlyers(data)
    },
    async  closeModal() {
      await this.resetData()
      this.$emit('close')
    },

    async searchFlyers() {
      const validate = await this.$refs.form.validate()
      if (validate) {
        this.searchFlyerModalOn = true
      }
    },

    async getCampaingByProgramId() {
      try {
        this.addPreloader()
        const params = {
          program_id: this.process.program,
        }

        const data = await ProcessSaleService.getCampaignByProgramId(params)
        if (data.status === 200) {
          this.campaigns = data.data
          this.campaigns.unshift({
            id: 0,
            value: 'All',
          })
          this.removePreloader()
        }
      } catch (error) {
        this.removePreloader()
        throw error
      }
    },

  },
}
</script>

<style scoped>
.caro6.modal-footer{
 display: block !important;

}

.caro7.footer-span{
  background: #b9bdcd!important
}
@media only screen and (min-width: 960px) {
.custom-caroV.k-input{
max-width: 126px !important;
width: 126px !important;
}

}
@media only screen and (min-width: 1440px) {
.custom-caroV.k-input{
max-width: 125px !important;
width: 125px !important;
}
}

@media only screen and (min-width: 1920px) {
.custom-caroV.k-input{
max-width: 113px !important;
width: 113px !important;
}
}

@media only screen and (max-device-width: 480px) {
.custom-caroV.k-input{
max-width: 400px !important;
width: 177px !important;
}
}
@media only screen and (device-width: 768px) {
.custom-caroV.k-input{
max-width: 100px !important;
width: 177px !important;
}
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .custom-caroV.k-input{
    max-width: 200px !important;
    width: 177px !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {

  .custom-caroV.k-input{
    max-width: 300px !important;
    width: 177px !important;
  }
}

</style>
