import {amgApi} from '@/service/axios'

// General services on module MetaMedia after refactoring
class ProcessSaleService {
    async searchProcessSale(body, page) {
        try {
            const data = await amgApi.post(`process-sale/search-process-sale?page=${page}`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async getCampaignByProgramId(params) {
        try {
            const data = await amgApi.post(`process-sale/get-campaign-by-program-id`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async insertTrackingProcessSale(params) {
        try {
            const data = await amgApi.post(`process-sale/insert-tracking-by-process-sale`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async getTrackingProcessSale(params) {
        try {
            const data = await amgApi.post(`process-sale/get-tracking-process-sale`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async deleteProcessSale(params) {
        try {
            const data = await amgApi.post(`process-sale/delete-process-sale`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async searchFlyerProcessSale(body, page) {
        try {
            const data = await amgApi.post(`process-sale/search-flyer-process-sale?page=${page}`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async insertNewProcessSale(params) {
        try {
            const data = await amgApi.post(`process-sale/insert-new-process`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }

    async updateFlyerProcessSale(params) {
        try {
            const data = await amgApi.post(`process-sale/update-flyer-process-sale`, params)
            return data
        } catch (error) {
            console.log('Something went wrong on getBankOfFlyers:', error)
            throw error
        }
    }




}

export default new ProcessSaleService()
