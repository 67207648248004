export default [

  {
    key: 'replies',
    sortable: false,
    label: 'Replies',
  },
  {
    key: 'invesment',
    sortable: false,
    label: 'Investment',
  },
  {
    key: 'date',
    sortable: false,
    label: 'date',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'Created By',
  },
  {
    key: 'updated_by',
    sortable: false,
    label: 'Updated By',
  },

]
