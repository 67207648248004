export default [
  {
    key: 'flyer',
    sortable: false,
    label: 'Flyer',
  },
  {
    key: 'name',
    sortable: true,
    label: 'Flyer Name',
  },
  {
    key: 'program',
    sortable: true,
    label: 'Program',
  },

  {
    key: 'replies',
    sortable: true,
    label: 'Replies',
    thStyle: { textAlign: 'center' }
  },

  {
    key: 'invesment',
    sortable: true,
    label: 'Investment',
    thStyle: { textAlign: 'center' }
  },
  {
    key: 'cpa',
    sortable: true,
    label: 'Cpa',
    thStyle: { textAlign: 'center' }
  },
  {
    key: 'date',
    sortable: true,
    label: 'Date',
  },

  {
    key: 'created',
    sortable: true,
    label: 'Created by',
  },

  {
    key: 'actions',
    sortable: false,
    label: 'Action',

  },

]
