<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="EDIT"
    size="lg"
    @hidden="closeModal"
  >

    <validation-observer ref="form">
      <b-row>
        <b-col>
          <label>INVESTMENT</label>
          <validation-provider
            v-slot="{ errors }"
            name="replies"
            rules="required"
          >
            <b-input-group>
              <money

                v-model="data.invesment"
                v-bind="vMoney"
                class="form-control"
                :class="{ 'border-error': errors[0] }"
              />

            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col>
          <label>REPLIES</label>
          <validation-provider
            v-slot="{ errors }"
            name="replies"
            rules="required"
          >
            <b-input-group>
              <b-form-input

                v-model="data.replies"
                :state="errors[0] ? false : null"

                type="number"
              />

            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col>
          <label>DATE</label>
          <validation-provider
              v-slot="{ errors }"
              name="replies"
              rules="required"
          >
            <b-input-group>
              <kendo-datepicker
                  id="created_date"
                  v-model="date"
                  v-mask="'##/##/####'"
                  :class="errors[0] ? 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caroV border-error-datepicker' : 'w-auto rounded bg-transparent k-picker-custom  picker-select-date custom-caroV'"
                  :min="minDate"
                  :max="maxDate"
                  :format="'MM/dd/yyyy'"
                  name="created_date"

              />

            </b-input-group>
          </validation-provider>
        </b-col>

      </b-row>

    </validation-observer>

    <template #modal-footer>
      <b-button
        variant="primary"
        @click="insertFlyerComments"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>

    </template>

  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import ProcessSaleService from '@/views/paragon/views/process-sale/process-sale.service'

export default {
  props: {

    idFlyer: null,

  },
  data() {
    return {
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      vMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      invesment: null,
      replies: null,
      spinnerOn: false,
      data: null,
      date: null

    }
  },

  created() {
    this.ownControl = true
    this.data = { ...this.idFlyer }
    this.date = this.data.date
  },
  computed: {
    ...mapGetters({

      currentUser: 'auth/currentUser',

    }),
  },
  methods: {

    closeModal() {
      this.$emit('close')
    },
    // eslint-disable-next-line consistent-return
    async insertFlyerComments() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          this.addPreloader()
          this.spinnerOn = true

          const params = {

            replies: this.data.replies,
            invesment: this.data.invesment,
            user_id: this.currentUser.user_id,
            flyer_id: this.idFlyer.process_id,
            date: this.date,
          }
          const params2 = {

            replies: this.data.replies,
            invesment: this.data.invesment,
            updated_by: this.currentUser.user_id,
            process_id: this.idFlyer.process_id,
            created_by: this.currentUser.user_id,
            date: this.date,
          }

          const data = await ProcessSaleService.updateFlyerProcessSale(params)
          const data1 = await ProcessSaleService.insertTrackingProcessSale(params2)
          if (data.status === 200 && data1.status === 200) {
            this.$emit('refresh')
            this.showSuccessSwal()
            this.closeModal()
            this.removePreloader()
          }
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style scoped>

</style>
