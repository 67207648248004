<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="Search Flyer"
    size="lg"

    hide-footer
    @hidden="closeModal"
  ><div> <div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['search-flyer-sale'].refresh()"
    >

      <b-table
        id="search-flyer-sale"
        slot="table"

        ref="search-flyer-sale"
        sticky-header="70vh"
        :items="search"
        no-provider-filtering
        :busy.sync="isBusy"

        :fields="fields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"

        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template
          v-slot:cell(name)="data"
        >
          <div
            class="d-flex flex-column  justify-content-center align-items-start "
          >
            {{ data.item.flyer_name }}
          </div>
        </template>

        <template v-slot:cell(flyer)="data">

          <div
            v-b-tooltip.hover.left="'Click to view flyer'"
            class="d-flex flex-column  justify-content-center align-items-start clickable "
          >
            <b-avatar

              square
              size="100"
              v-bind="mainProps"
              :src="data.item.route_thumb"
              rounded="lg"
              icon="file-earmark-image-fill"
            />

          </div>

        </template>

        <template #cell(actions)="data">

          <b-form-group>
            <b-form-checkbox
              v-model="accepted"
              :value="data.item.id"

              :name="data.item.id"
              class="mt-1"

              @change="saveDataUpload(data.item,accepted)"
            />

          </b-form-group>
        </template>
        <template #head(actions)>
          <b-form-checkbox
            v-model="selectAll"
            @change="all"
          />
        </template>
      </b-table>

    </filter-slot>

  </div></div></b-modal>
</template>

<script>
import dataFields from '@/views/paragon/views/process-sale/procces-sale-module/modals/fields.data'
import ProcessSaleService from '@/views/paragon/views/process-sale/process-sale.service'
import { mapGetters, mapMutations } from 'vuex'
import moment from "moment";

export default {
  props: {
    process: {},
  },
  data() {
    return {
      mainProps: {
        center: false,
        fluidGrow: false,

        width: 100,

        block: false,

        fluid: true,

      },
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,

      spinnerOn: false,
      items: [],
      isBusy: false,
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      basicSearch: true,

      startPage: null,
      toPage: null,
      accepted: [],
      selectAll: false,
      dataItem: [],
      showImage: null,
      itemImage: [],
      date:null
    }
  },
  watch: {
    items(newVal) {
      if (this.items.length === this.accepted.length) { this.selectAll = true }
    },
    accepted(newVal) {
      if (this.items.length !== this.accepted.length) { this.selectAll = false }
      if (this.items.length > 0 && (this.items.length === this.accepted.length)) { this.selectAll = true }
    },

  },
  computed: {

    ...mapGetters({

      currentUser: 'auth/currentUser',
      uploadFlyersData: 'ProcessSaleStore/G_UPLOAD_FLYERS',
      infoData: 'ProcessSaleStore/G_UPLOAD_FLYERS_INFO',
    }),

  },

  created() {
    this.date = moment().format('YYYY-MM-DD')
    this.ownControl = true
  },
  methods: {
    all() {
      if (this.selectAll === true) {
        this.dataItem = []
        this.items.forEach(item => {
          const dataReal = {
            campaign: item.campaign,
            count_flyer: item.count_flyer,
            file_name: item.file_name,
            flyer_name: item.flyer_name,
            bank_of_flyer_id: item.id,
            route: item.route,
            route_thumb: item.route_thumb,
            state: item.state,
            invesment: 0,
            cpa: 0,
            replies: 0,

          }

          this.dataItem.push(dataReal)
          this.dataItem = this.dataItem.filter((item2, index) => this.dataItem.indexOf(item2) === index)
          this.accepted.push(dataReal.bank_of_flyer_id)
          this.accepted = this.accepted.filter((item2, index) => this.accepted.indexOf(item2) === index)
        })
      }
      if (this.selectAll === false) {
        this.items.forEach(item => {
          const dataReal = {
            campaign: item.campaign,
            count_flyer: item.count_flyer,
            file_name: item.file_name,
            flyer_name: item.flyer_name,
            bank_of_flyer_id: item.id,
            route: item.route,
            route_thumb: item.route_thumb,
            state: item.state,
            invesment: 0,
            cpa: 0,
            replies: 0,

          }
          this.dataItem.splice(dataReal, 1)

          this.accepted = []
        })
      }
    },
    pushImage() {
      const imageArray = []
      this.infoData.forEach(element => {
        imageArray.push({
          title: element.campaign,
          src: element.route,
          thumb: element.route_thumb,
        })
      })
      this.itemImage = imageArray
    },

    saveDataUpload(data, value) {
      const dataReal = {
        campaign: data.campaign,
        count_flyer: data.count_flyer,
        file_name: data.file_name,
        flyer_name: data.flyer_name,
        bank_of_flyer_id: data.id,
        route: data.route,
        route_thumb: data.route_thumb,
        state: data.state,
        invesment: 0,
        cpa: 0,
        replies: 0,

      }
      const checked = this.accepted.includes(data.id)

      if (checked === true) {
        this.dataItem.push(dataReal)
      }

      if (checked === false) {
        this.dataItem.splice(dataReal, 1)
      }
    },
    ...mapMutations({
      uploudFlyers: 'ProcessSaleStore/M_SET_UPLOAD_FLYERS',
      uploudData: 'ProcessSaleStore/M_SET_UPLOAD_FLYERS_INFO',
    }),
    closeModal() {
      const result = this.dataItem.filter((item, index) => this.dataItem.indexOf(item) === index)
      this.uploudData(result)
      this.uploudFlyers(this.accepted)
      this.$emit('close')
    },

    async search(ctx) {
      if (this.uploadFlyersData) {
        this.accepted = this.uploadFlyersData
        this.dataItem = this.infoData
      }
      try {
        const params = {
          perPage: this.paginate.perPage,
          text: this.filterPrincipal.model,
          date: this.date,
          campaign_id: this.process.campaign_id,
          program_id: this.process.program,

        }
        const data = await ProcessSaleService.searchFlyerProcessSale(params, ctx.currentPage)

        this.items = data.data.data

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style scoped>

</style>
