<template>
  <div>
    <CoolLightBox
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <b-container
      class="m-0 w-100"
      fluid
    >

      <header-slot>

        <template #actions>
          <b-button
            variant="success"
            class="mr-1"
            @click="openInsertProcessSale"
          >
            <feather-icon
              icon="PlusIcon"
              size="15"
              class=" text-white"
            />
            ADD
          </b-button>
        </template>

      </header-slot>

      <div class="border-top-primary border-3 border-table-radius px-0">

        <div>
          <filter-slot

            :filter="filter"
            :filter-principal="filterPrincipal"
            :total-rows="totalRows"
            :paginate="paginate"
            :start-page="startPage"
            :to-page="toPage"
            @reload="$refs['process-sale'].refresh()"
          >

            <b-table
              id="process-sale"
              slot="table"

              ref="process-sale"
              sticky-header="70vh"
              :items="search"
              no-provider-filtering
              :busy.sync="isBusy"

              :fields="fields"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              :filter="filter"
              show-empty

              class="table-scroll-per"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template
                v-slot:cell(name)="data"
              >
                <div
                  class="d-flex flex-column  justify-content-center align-items-start "
                >
                  {{ data.item.flyer_name }}
                </div>
              </template>

              <template v-slot:cell(flyer)="data">

                <div
                  v-b-tooltip.hover.left="'Click to view flyer'"
                  class="d-flex flex-column  justify-content-center align-items-start clickable "
                  @click="openImage(data.index)"
                >
                  <b-avatar

                    square
                    size="50"
                    v-bind="mainProps"
                    :src="data.item.route_thumb"
                    rounded="lg"
                    icon="file-earmark-image-fill"
                  />

                </div>

              </template>
              <template #cell(replies)="data">
                <div class="text-center w-100">
                  {{ data.item.replies }}
                </div>

              </template>
              <template #cell(cpa)="data">
                <div class="text-center w-100">
                  $ {{ data.item.cpa }}

                </div>

              </template>
              <template #cell(date)="data">
                <div>
                  {{ data.item.date | myGlobal }}

                </div>

              </template>

              <template #cell(invesment)="data">
                <div class="text-center w-100">
                  $ {{ data.item.invesment | currency }}
                </div>

              </template>

              <template #cell(created)="data">
                <div style="max-width: 100px; width: 100px">
                  {{ data.item.user_upload }}
                  <br>
                  {{ data.item.created_at | myGlobal }}
                </div>
              </template>
              <template #cell(actions)="data">
                <div style="max-width: 95px; width: 95px">
                  <feather-icon
                    class="text-primary  mr-1 cursor-pointer"
                    size="18"
                    icon="ListIcon"
                    @click="opentrackingProcessSale(data.item.process_id)"
                  />
                  <feather-icon
                    class="text-warning mr-1 cursor-pointer"
                    icon="EditIcon"
                    size="18"
                    @click="openEditFlyerModal(data.item)"
                  />
                  <feather-icon
                    class="text-danger cursor-pointer"
                    size="18"
                    icon="TrashIcon"
                    @click="deleteProcessSale(data.item.process_id)"
                  />

                </div>
              </template>

              <template #custom-foot>
                <b-tr class="bg-dark "  >
                  <b-th >
                    <span class="title" style="font-size: 15px">SUBTOTAL</span>
                    <br/>
                    <span class="title" style="font-size: 15px">TOTAL</span>
                  </b-th>

                  <b-th />
                  <b-th />
                  <b-th class="footer-dark">
                    <div class="footer-span w-100">
                      <span class="font-small-3 ">{{
                          sub_total_replies
                        }} </span>
                    </div>
                    <br/>
                    <div class="footer-span w-100">
                      <span class="font-small-3 ">{{
                          total_replies
                        }} </span>
                    </div>
                  </b-th>
                  <b-th class="footer-dark">
                    <div class="footer-span w-100">
                      <span class=" font-small-3 ">$ {{
                          sub_total_investment | currency
                        }} </span>
                    </div>
                    <br/>
                    <div class="footer-span w-100">
                      <span class=" font-small-3 ">$ {{
                          total_investment | currency
                        }} </span>
                    </div>
                  </b-th>
                  <b-th
                      class="footer-dark"
                      style="max-width: 200px; width: 150px"
                  >
                    <div class="footer-span w-100">
                      <span class=" font-small-3  ">$ {{
                          sub_total_cpa | currency
                        }} </span>
                    </div>
                    <br/>
                    <div class="footer-span w-100">
                      <span class=" font-small-3  ">$ {{
                          total_cpa | currency
                        }} </span>
                    </div>
                  </b-th>
                  <b-th />
                  <b-th />
                  <b-th />

                </b-tr>


              </template>
            </b-table>

          </filter-slot>

        </div>
      </div>
    </b-container>
    <insert-process-sale-modal
      v-if="processSaleModalOn"
      @refresh="refresh"
      @close="closeInsertProcessSale"
    />

    <edit-flyer-sale-modal
      v-if="editFlyerSaleModal"
      :id-flyer="idFlyer"
      @refresh="refresh"
      @close="closeEditFlyerModal"
    />
    <modal-tracking-process-sale
      v-if="modalTrackingProcessSaleOn"
      :index="processSaleId"
      @close="closetrackingProcessSale"
    />
  </div>
</template>
<script>

import dataFields
from '@/views/paragon/views/process-sale/procces-sale-module/fields.data.js'
import dataFilters
from '@/views/paragon/views/process-sale/procces-sale-module/filters.data.js'
import ProcessSaleService from '@/views/paragon/views/process-sale/process-sale.service'
import { mapGetters } from 'vuex'
import InsertProcessSaleModal
from '@/views/paragon/views/process-sale/procces-sale-module/modals/InsertProcessSaleModal'
import EditFlyerSaleModal from '@/views/paragon/views/process-sale/procces-sale-module/modals/EditFlyerSaleModal'
import CoolLightBox from 'vue-cool-lightbox'
import TaskService from '@/service/task'
import ModalTrackingProcessSale
from '@/views/paragon/views/process-sale/procces-sale-module/modals/trackings/ModalTrackingProcessSale'

export default {
  components: {
    ModalTrackingProcessSale, EditFlyerSaleModal, InsertProcessSaleModal, CoolLightBox,
  },
  data() {
    return {
      showImage: null,
      itemImage: [],
      mainProps: {
        center: false,
        fluidGrow: false,

        width: 100,

        block: false,

        fluid: true,

      },
      items: [],
      isBusy: false,
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,

      selectAll: false,
      total_cpa: null,
      total_investment: null,
      total_replies: null,
      sub_total_cpa: null,
      sub_total_investment: null,
      sub_total_replies: null,
      processSaleModalOn: false,
      editFlyerSaleModal: false,
      idFlyer: null,
      campaigns: [],
      modalTrackingProcessSaleOn: false,
      processSaleId: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      programs: 'SocialNetworkGlobalStore/G_PROGRAMSC',
    }),
    programsData: {
      get() {
        return this.filter[2].model
      },
      set(value) {
        this.filter[2].model = value
      },
    },
  },
  watch: {
    async programsData(newVal) {
      await this.getCampaingByProgramId(newVal)
    },
  },
  async created() {
    try {
      await Promise.all([
        this.$store.dispatch('SocialNetworkGlobalStore/A_GET_PROGRAMS'),
      ])
      this.filter[2].options = this.programs
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    opentrackingProcessSale(id) {
      this.processSaleId = id
      this.modalTrackingProcessSaleOn = true
    },
    closetrackingProcessSale(id) {
      this.processSaleId = id
      this.modalTrackingProcessSaleOn = false
    },
    async getCampaingByProgramId(program_id) {
      try {
        this.addPreloader()
        const params = {
          program_id,
        }
        const data = await ProcessSaleService.getCampaignByProgramId(params)
        if (data.status === 200) {
          this.campaigns = data.data
          this.campaigns.unshift({
            id: 0,
            value: 'All',
          })

          this.removePreloader()
        }
      } catch (error) {
        this.removePreloader()
        console.log('getCampaingByProgramId [ACTION]', error)
        throw error
      }
    },
    openImage(index) {
      this.showImage = index
    },
    pushImage() {
      const imageArray = []
      this.items.forEach(element => {
        imageArray.push({
          title: element.campaign,
          src: element.route,
          thumb: element.route_thumb,
        })
      })
      this.itemImage = imageArray
    },
    openEditFlyerModal(data) {
      this.idFlyer = data
      this.editFlyerSaleModal = true
    },
    closeEditFlyerModal() {
      this.editFlyerSaleModal = false
    },
    refresh() {
      this.$refs['process-sale'].refresh()
    },
    openInsertProcessSale() {
      this.processSaleModalOn = true
    },
    closeInsertProcessSale() {
      this.processSaleModalOn = false
    },

    async deleteProcessSale(delete_id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const params = {
            user_id: this.currentUser.user_id,
            id: delete_id,
          }
          await ProcessSaleService.deleteProcessSale(params)

          this.$refs['process-sale'].refresh()
          this.showSuccessSwal()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async search(ctx) {
      try {
        let orderBy = 2
        let sortDirection = 'desc'
        if (ctx.sortBy === 'program') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 17
        } else if (ctx.sortBy === 'campaign') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 11
        } else if (ctx.sortBy === 'name') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 20
        } else if (ctx.sortBy === 'replies') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 3
        } else if (ctx.sortBy === 'created_by') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 1
        } else if (ctx.sortBy === 'invesment') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 4
        } else if (ctx.sortBy === 'cpa') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 5
        } else if (ctx.sortBy === 'created') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 2
        } else if (ctx.sortBy === 'date') {
          if (ctx.sortDesc) sortDirection = 'desc'
          else sortDirection = 'asc'
          orderBy = 26
        }

        const params = {
          perPage: this.paginate.perPage,
          orderby: orderBy,
          order: sortDirection,
          from: this.filter[0].model,
          to: this.filter[1].model,
          program: this.filter[2].model,
          campaign_id: null,
          text: this.filterPrincipal.model,

        }
        const data = await ProcessSaleService.searchProcessSale(params, ctx.currentPage)

        this.items = data.data.data
        this.pushImage()
        if (this.items.length > 0) {
          this.total_cpa = this.items[0].total_cpa
          this.total_investment = this.items[0].total_investment
          this.total_replies = this.items[0].total_replies
          this.sub_total_cpa = this.items[0].sub_cpa
          this.sub_total_investment = this.items[0].sub_invesment
          this.sub_total_replies = this.items[0].sub_replies
        }
        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

.table-scroll-per tfoot tr,
.table-scroll-per tfoot th{
  position: sticky !important;
  bottom: 0 !important;



}

</style>
