<template>
  <b-modal
      v-model="ownControl"
      scrollable
      hide-footer
      title-class="h3 text-white font-weight-bolder"
      title="Tracking"
      size="lg"
      @hidden="closeModal"
  >
    <div>
      <b-card
          no-body

          class="mb-0"
      >

        <b-table

            small

            :fields="fields"
            :items="search"
            class="font-small-3 text-center"
            show-empty
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"/>
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(replies)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.replies }}
            </p>

          </template>

          <template v-slot:cell(invesment)="data">
            <p
                class="mb-0 font-weight-bold "
            >
             $ {{ data.item.invesment | currency }}
            </p>

          </template>
          <template v-slot:cell(date)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.date | myGlobal }}
            </p>

          </template>

          <template v-slot:cell(created_by)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.created_by }}
              <br/>
              {{ data.item.created_at | myGlobalDay }}
            </p>

          </template>
          <template v-slot:cell(updated_by)="data">
            <p
                class="mb-0 font-weight-bold "
            >
              {{ data.item.updated_by ?  data.item.updated_by : '-' }}
              <br/>
              {{ data.item.updated_at| myGlobalDay    }}
            </p>

          </template>
        </b-table>
      </b-card>
    </div>
  </b-modal>
</template>
<script>

import dataFields
  from '@/views/paragon/views/process-sale/procces-sale-module/modals/trackings/fields.data.js'


import ProcessSaleService from "@/views/paragon/views/process-sale/process-sale.service";

export default {
  props: {

    index: null,

  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      fields: dataFields,
      items: {},
    }
  },
  created() {
    this.ownControl = true
  },
  methods: {

    closeModal() {
      this.$emit('close')
    },

    async search() {
      try {
        const params = {
          process_id: this.index,

        }
        const data = await ProcessSaleService.getTrackingProcessSale(params)

        this.items = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
