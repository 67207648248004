export default [
  {
    key: 'flyer',
    sortable: false,
    label: 'Flyer',
  },
  {
    key: 'name',
    sortable: false,
    label: 'Flyer Name',
  },


  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },


]
